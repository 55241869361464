import { navigateTo, defineNuxtRouteMiddleware } from '#imports';
import { useUserAuth } from '~/composables/user/useUserAuth';

const { isLoggedIn } = useUserAuth();

export default defineNuxtRouteMiddleware((to, from) => {
  if (process.client) {
    if (!isLoggedIn.value) {
      return navigateTo('/');
    }
  }
});
